import { graphql } from 'gatsby';
import React from 'react';
import UnderlinedButton from '../components/Button/UnderlinedButton';
import CTA from '../components/CTA';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import SquareMediaText from '../components/SquareMediaText';
import defaultPicture from '../images/PellerinGuitares_140.jpeg';

const translations = [
  {
    locale: 'en',
    url: '/en/about/',
  },
];

export default function AProposPage({ data }) {
  return (
    <Layout translations={translations}>
      <article className="space-y-48">
        <PageHeader>
          À <br />
          propos
        </PageHeader>

        <section className="container">
          <div className="">
            <Heading level={2}>
              Le parcours du luthier&nbsp;: <br />
              25&thinsp;ans de son et de passion.
            </Heading>

            <div className="lg:columns-2 space-y-4">
              <p>
                Après sa formation de luthier, Michel Pellerin a travaillé
                brièvement dans une usine de guitare. Il a compris rapidement
                que la production à la chaîne n’était pas pour lui. Il préfère
                de loin se donner corps et âme pour créer un instrument unique.
              </p>

              <p>
                Il commence alors à réparer des guitares et reconnait les
                défauts des instruments des compagnies. Cette obsession de tout
                noter et de créer une guitare exceptionnelle l’habite encore
                aujourd’hui.
              </p>

              <p>
                Michel a une capacité unique à entendre vibrer le bois avant
                même que la guitare soit confectionnée.
              </p>

              <p>
                Cette expertise, il l’a transmis pendant quelques années aux
                étudiants en réparation et la finition du Cégep Limoilou. Les
                plus grands luthiers internationaux font aussi appel à ses
                services pour des cours privés.
              </p>

              <p>
                En partageant son expertise, Michel s’oblige à se questionner, à
                revoir ses façons de faire et à s’améliorer constamment. Parce
                que sa quête de la perfection n’est jamais terminée.
              </p>

              <p>
                À ce jour, Michel Pellerin et son collègue François C.Paradis
                ont créé et peaufiné plus de 250 guitares exceptionnelles.
              </p>
            </div>
          </div>
        </section>

        <section className="container">
          <SquareMediaText
            media={data.reputationImage}
            className="wide-content-contained"
          >
            <Heading level={2} className="!mt-0">
              Une réputation qui résonne à l’international
            </Heading>

            <p>
              Des luthiers de classe mondiale comme Michel Pellerin, il en
              existe peu. C’est pourquoi même les plus grands font appel à son
              expertise pour des cours privés. Tous reconnaissent son approche
              méthodique qui consiste à noter toutes les spécificités de chaque
              guitare afin d’optimiser et d’améliorer sans cesse son travail. En
              effet, Michel comprend le transfert d’énergie et les relations
              entre les différentes parties de la guitare. C’est un véritable
              virtuose qui continue de s’améliorer constamment.
            </p>
          </SquareMediaText>

          <SquareMediaText
            media={data.playImage}
            className="wide-content-contained"
            left
          >
            <Heading level={2} className="!mt-0">
              Jouer la mesure sans demi-mesure sur la qualité
            </Heading>

            <p>
              Michel Pellerin maitrise les matériaux vivants et organiques. Son
              savoir-faire inégalé lui permet de créer des instruments qui
              vieillissent bien. Sa technique est réglée comme un métronome. Il
              n’y a pas de place à l’erreur. Le design, les matériaux,
              l’ajustement, la résonnance, tout doit être en symphonie. Michel
              Pellerin exige toujours la note parfaite. Rien de moins.
            </p>
          </SquareMediaText>

          <SquareMediaText
            media={data.workImage}
            src={defaultPicture}
            className="wide-content-contained"
          >
            <Heading level={2} className="!mt-0">
              Les artisans à l’oeuvre
            </Heading>

            <p>
              Voici Michel Pellerin et François C.Paradis dans l'atelier en
              plein travail de confection. Entouré d’essences de bois et
              d’instruments, Michel et François sont en accord parfait avec leur
              passion. Inspirés et concentrés, ils laissent aller toute leur
              musicalité pour créer des guitares qui transforment la vie des
              musiciens.
            </p>
          </SquareMediaText>
        </section>

        <section>
          <CTA>
            <Heading level={2} className="!mt-0">
              Des guitares qui rockent
            </Heading>

            <p className="max-w-prose my-4">
              À travers les années, Michel Pellerin et son acolyte François
              C.Paradis ont imaginé et conçu plus de 250 guitares distinctives.
              Quoique le terme œuvre d’art est plus approprié, voici un aperçu
              des plus belles guitares Pellerin.
            </p>

            <UnderlinedButton to="/realisations" className="text-xl my-5">
              Voir les créations
            </UnderlinedButton>
          </CTA>
        </section>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query {
    reputationImage: file(name: { eq: "PellerinGuitares_122" }) {
      childImageSharp {
        gatsbyImageData(width: 768, height: 768)
      }
    }
    playImage: file(name: { eq: "PellerinGuitares_169" }) {
      childImageSharp {
        gatsbyImageData(
          width: 768
          height: 768
          transformOptions: { cropFocus: CENTER }
        )
      }
    }
    workImage: file(name: { eq: "PellerinGuitares_150" }) {
      childImageSharp {
        gatsbyImageData(
          width: 768
          height: 768
          transformOptions: { cropFocus: EAST }
        )
      }
    }
  }
`;
